<template>
    <div id="cs-header-menu" class="cs-header-menu-block cs-block cs-no-padding">
        <div class="cs-base-block">
            <CSMenu :menuStyle="data.style" :logo="data.logo" :links="data.links" :menu="menu" />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    import {computed} from '../libs/common-fn';

export default {
        name: "Menu",
        components: {
            CSMenu: () => import('../components/CSMenu.vue'),
        },
        data() {
            return {
                menu:{}
            }
        },
        props: {
            data: {
                type: Object,
            default: () => {},
            },
        },
        methods: {
            ...mapActions('menu', ['retrieve']),
        },
        mounted() {
            this.retrieve({id: this.data.menu, l1:4, l2:3, l3:4, l4:2}).then(menu =>{
                if(menu) this.menu = Object.values(menu);
            })
        },
        computed: computed('HeaderMenu')
    };

</script>

<style lang="scss" scoped>
    @import "../styles/main.scss";
    
    :v-deep {
        .cs-interactions > * {
            margin-right: 15px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }
</style>